const formatDataValuesChart = (data) => {
    const dataChart = {
        options: {
            chart: {
                id: 'sales-area-chart',
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                labels: {
                    formatter: (val) => {
                        if (isNaN(val)) {
                            return val
                        } else {
                            if (val > 1000000) {
                                return `${parseFloat(val / 1000000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mi`;

                            } else {
                                return `${parseFloat(val / 1000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mil`;
                            }
                        }
                    }
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: '100%',
                    borderRadius: 2,
                    barHeight: '90%',

                },
            },
            dataLabels: {
                formatter: function (val) {
                    if (val > 1000000) {
                        return `${parseFloat(val / 1000000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mi`;

                    } else {
                        return `${parseFloat(val / 1000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mil`;
                    }
                },
            },
            fill: {
                opacity: 1,
            },
            colors: ['#252f3e', '#0d3d85'],
            title: {
                text: 'Valores mensais',
                align: 'center',
                style: {
                    color: '#444'
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return `${value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return `${value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                    },
                },
            },
        },

        series: [
            {
                name: 'Valores Líquidos',
                data: data.map(d => (parseFloat(d.value_approved || 0).toFixed(2))),
            },
            {
                name: 'Valores Brutos',
                data: data.map(d => (parseFloat(d.gross_value || 0).toFixed(2))),
            },
        ],
    };
    return dataChart
}

const formatDataByValuesChart = (data, name) => {
    const chartData = {
        options: {
            chart: {
                id: 'sales-chart',
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: data.map(d => d.name || ''),
                labels: {
                    formatter: (val) => {
                        if (isNaN(val)) {
                            return val
                        } else {
                            if (val > 1000000) {
                                return `${parseFloat(val / 1000000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mi`;

                            } else {
                                return `${parseFloat(val / 1000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mil`;
                            }
                        }
                    }
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: '100%',
                    borderRadius: 2,
                    barHeight: '80%',

                },
            },
            dataLabels: {
                formatter: function (val) {
                    if (val > 1000000) {
                        return `${parseFloat(val / 1000000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mi`;

                    } else {
                        return `${parseFloat(val / 1000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mil`;
                    }
                },
            },
            fill: {
                opacity: 1,
            },
            colors: ['#252f3e', '#0d3d85'],
            title: {
                text: name,
                align: 'center',
                style: {
                    color: '#444'
                }
            },
            yaxis: {
                title: {
                    text: name
                },
                labels: {
                    formatter: (val) => {
                        if (isNaN(val)) {
                            return val
                        } else {
                            return `${parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                        }
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return `${parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                    },
                },
            },
        },
        series: [
            {
                name: 'Quantidade de Negócios:',
                data: data.map(d => d.value_approved),
            },
        ],
    };

    return chartData
}

const formatDataToChart = (data, name) => {
    const chartData = {
        options: {
            chart: {
                id: 'sales-chart',
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: data.map(d => d.label || ''),
                title: 'teste'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                colors: ['#252f3e'],
            },
            title: {
                text: name,
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#444'
                }
            }
        },
        series: [
            {
                name: 'Quantidade de Negócios:',
                data: data.map(d => d.data),
            },
        ],
    };

    return chartData
}

const formatDataProgressChart = (data, type, title) => {
    let total = 0
    if (type == 'status') {
        const totalProposals = data.reduce((sum, arr) => {
            return sum + arr.data || 0
        }, 0)
        const totalApproved = !!data.find(d => d.label == 'APROVADA') ? data.find(d => d.label == 'APROVADA').data : 0
        total = totalProposals == 0 ? 0 : (totalApproved / totalProposals) * 100
    } else if (type == 'leads') {
        total = data[0].total == 0 ? 0 : (data[0].win / data[0].total) * 100
    }

    const chartData = {
        options: {
            chart: {
                type: 'radialBar',
                offsetY: 20,
                sparkline: {
                    enabled: true,
                },
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: "70%",
                        background: "#293450"
                    },
                    track: {
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            blur: 4,
                            opacity: 0.15
                        }
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#fff",
                            fontSize: "13px"
                        },
                        value: {
                            color: "#fff",
                            fontSize: "30px",
                            show: true
                        }
                    }
                }
            },
            stroke: {
                lineCap: "round"
            },
            fill: {
                colors: ['#3366CC'],
            },
            series: [(total).toFixed(2)],
            labels: [title],
        },
    };
    return chartData
}

const getUniqueValues = (array, key) => {
    return [...new Set(array.map(item => item[key]))];
};

const createSeries = (uniquesFields, months, key, data, field) => {
    return uniquesFields.map(uniqueField => ({
        name: uniqueField,
        data: months.map(month => {
            const match = data.find(item => item[field] === uniqueField && item.month === month);
            return match ? parseFloat(match[key]) : 0;
        }),
    }));
};

const formatDataOwnChartSell = (data, field, title, vertical, selectedValue) => {
    const clients = getUniqueValues(data, field);
    const months = getUniqueValues(data, 'month');
    const valueToShowSells = selectedValue == 1 ? 'net_value_approved' :
        selectedValue == 2 ? 'gross_value_approved' :
            selectedValue == 3 ? 'net_value_proposal' :
                'gross_value_proposal'

    const series = [
        ...createSeries(clients, months, valueToShowSells, data, field),
    ];

    const chartData = {
        options: {
            chart: {
                id: title,
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: months,
                title: title
            },
            plotOptions: {
                bar: {
                    horizontal: vertical,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },

            title: {
                text: title,
                align: 'center',
                style: {
                    color: '#444'
                },
            }
        },
        series: series
    };

    return chartData
}

const formatDataOwnChartActivities = (data, title, vertical, selectedStatus) => {
    const users = getUniqueValues(data, 'name');


    const series = selectedStatus.map(status => {
        const statusField = [
            '',
            'total_win',
            'total_loss',
            'total_inProgress',
            'total_delay',
            'total_win_delay',
            'total_activities'
        ]

        const statusToShow = [
            '',
            'Ganho',
            'Perda',
            'Em Andamento',
            'Ganhas após o prazo',
            'Todas as Atividades'
        ]
        return {
            name: statusToShow[status],
            data: users.map(user => {
                const match = data.find(item => item.name === user);
                return match ? parseInt(match[statusField[status]]) : 0

            })
        }
    })


    const chartData = {
        options: {
            chart: {
                id: title,
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: users,
                title: title
            },
            plotOptions: {
                bar: {
                    horizontal: vertical,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },

            title: {
                text: title,
                align: 'center',
                style: {
                    color: '#444'
                },
            }
        },
        series: series
    };

    return chartData
}


const createSeries2 = (data, metrics) => {
    const metricTranslate = {
        'net_value_approved': 'Valor Líquido Faturado',
        'approved_gross_value': 'Valor Bruto Faturado',
        'net_value_proposal': 'Valor Líquido Aprovado',
        'gross_value_proposal': 'Valor Bruto Aprovado',
        'received_value': 'Valor Recebido',
        'comission_value': 'Valor da Comissão'
    }

    return metrics.map(metric => ({
        name: metricTranslate[metric],
        data: data.map(d => d[metric])
    }));
};

const formatDataForColumnChart = (data, filters, type) => {
    const categoryName = (filters.groupby || '').indexOf('.') > -1 ? filters.groupby.split('.')[1] : filters.groupby
    const categories = data.map(d => d[categoryName] ?? '')
    const series = createSeries2(data, filters.metrics)

    const options = {
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                horizontal: type == 3 ? true : false,
                columnWidth: '100%',
                borderRadius: 5,
                barHeight: '100%',

            },
        },
        dataLabels: {
            formatter: function (val) {
                if (val > 1000000) {
                    return `${parseFloat(val / 1000000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mi`;

                } else {
                    return `${parseFloat(val / 1000).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} mil`;
                }
            },
            style: {
                colors: ['white'], 
                fontSize: '12px',    
                fontWeight: 'bold',  
            },
            dropShadow: {
                enabled: true,
                top: 0,                
                left: 0,               
                blur: 4,               
                opacity: 1,            
                color: '#000000'       
            }
        

        },
     
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
            labels: {
                formatter: (val) => {
                    if (isNaN(val)) {
                        return val
                    } else {
                        return `${parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                    }
                }
            }
        },
        yaxis: {
            title: {
                text: filters.name
            },
            labels: {
                formatter: (val) => {
                    if (isNaN(val)) {
                        return val
                    } else {
                        return `${parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                    }
                }
            }
        },
        fill: {
            opacity: 1,

        },

        colors: ['#252f3e', '#0d3d85'],
        tooltip: {
            y: {
                formatter: function (val) {
                    return `${parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
                }
            }
        }
    }
    return {
        series,
        options
    }
}

export default {
    formatDataToChart,
    formatDataProgressChart,
    formatDataValuesChart,
    formatDataByValuesChart,
    formatDataOwnChartSell,
    formatDataOwnChartActivities,
    formatDataForColumnChart
}