import React, { useState, useEffect } from 'react'
import clsx from 'clsx';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';

import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
import { Divider, Icon, IconButton, Typography } from '@material-ui/core';
import '../../newLeads/Leads.css'
import { Input } from '@mui/material';
const loggedUser = Store.USER
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 720,
        padding: 15
    },
}));

const ListGeneralDashes = ({
    open,
    onClose,
    loadGeneralDash,
    openDelete,
    generalDashes,
    generalDashSelected
}) => {

    const classes = useStyles();

    return (
        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'sticky', top: 0, backgroundColor: 'white' }}>
                <Typography className='title pointer'>DASHES</Typography>
            </div>
            {(generalDashes || []).map(dash => (
                <div className="funnelDiv"
                    style={{
                        backgroundColor: dash.id_general_dash == generalDashSelected.id_general_dash ? '#1b2330' : 'white',
                        color: dash.id_general_dash == generalDashSelected.id_general_dash ? 'white' : 'black'
                    }}
                >
                    <Typography
                        style={{ width: '75%', height: '80%' }}
                        onClick={() => loadGeneralDash(dash)}
                    >{dash.name}</Typography>
                    <div>
                        {(loggedUser.id_user === dash.user_cad || loggedUser.role === 'admin') && (
                            <IconButton>
                                <Icon
                                    style={{ color: dash.id_general_dash == generalDashSelected.id_general_dash ? 'white' : 'black' }}
                                    onClick={() => openDelete(dash)}
                                >
                                    delete
                                </Icon>
                            </IconButton>
                        )}
                    </div>
                </div>
            ))}

        </SwipeableDrawer>
    )
}

export default ListGeneralDashes